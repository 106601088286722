import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Page_Price from './components/Page_Price';
import Page_Otp from './components/Page_Otp'
import ApplySenderName from './components/ApplySenderName';
import UserProfile from './components/UserProfile';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import SendSMS from './components/SendSMS';
import PrivateRoute from './components/PrivateRoute';
import AuthProvider from './context/AuthContext';
import Footer from './components/Footer';
import Maintenance from './components/Maintenance';
import api from './api'; // Import your API module
import LoadingSpinner from './components/LoadingSpinner';

const App = () => {
  const [isSiteOn, setIsSiteOn] = useState(true); // Default to true (site is on)
  const [loading, setLoading] = useState(true); // State to handle loading state

  useEffect(() => {
    const fetchSiteStatus = async () => {
      try {
        const response = await api.get('/site-status');
        setIsSiteOn(response.data.isSiteOn); // Assuming the response contains an 'isSiteOn' field
      } catch (error) {
        console.error('Failed to fetch site status', error);
        setIsSiteOn(false); // Default to off if there's an error
      } finally {
        setLoading(false); // Set loading to false once the status is fetched
      }
    };

    fetchSiteStatus();
  }, []);

  if (loading) {
    return <LoadingSpinner />; // Use the beautiful loading spinner here
  }

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {isSiteOn ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/price" element={<Page_Price />} />
              <Route path="/smsotp" element={<Page_Otp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/sendsms/*" element={<SendSMS />} />
                <Route path="/sendername/*" element={<ApplySenderName />} />
                <Route path="/profile/*" element={<UserProfile />} />
              </Route>
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/price" element={<Page_Price />} />
              <Route path="*" element={<Maintenance />} />
            </>
          )}
        </Routes>
        {isSiteOn && <Footer />}
      </Router>
    </AuthProvider>
  );
};

export default App;