import React, { useState, useContext } from 'react';
import { Form, Button, Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './Login.css';
import logo from '../assets/logo.png';
import { FaLine } from "react-icons/fa";
import Swal from 'sweetalert2';
import NavbarHome from "./NavbarHome"

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/dashboard');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เข้าสู่ระบบไม่สำเร็จ',
        text: 'อีเมลหรือรหัสผ่านผิด',
      });
    }
  };

  return (
    <>
    
      <NavbarHome />

      <Container className="login-container">
        <h2 className="login-title">เข้าสู่ระบบ</h2>
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Group controlId="formBasicEmail">
            <Form.Label>อีเมล</Form.Label>
            <Form.Control
              type="email"
              placeholder="กรอกอีเมล"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>รหัสผ่าน</Form.Label>
            <Form.Control
              type="password"
              placeholder="กรอกรหัสผ่าน"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button style={{ marginTop: '1rem' }} variant="primary" type="submit" className="login-button2">
            ล็อคอิน
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default Login;