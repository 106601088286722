// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    border-top: 1px solid #ddd;
  }
  
  .footer h5 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .footer ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .footer ul li {
    margin-bottom: 10px;
  }
  
  .footer ul li a {
    color: #343a40;
    text-decoration: none;
  }
  
  .footer ul li a:hover {
    text-decoration: underline;
  }
  
  .footer .text-center {
    margin-top: 20px;
    font-size: 0.9rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,0BAA0B;EAC5B;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB","sourcesContent":[".footer {\r\n    background-color: #f8f9fa;\r\n    padding: 20px 0;\r\n    border-top: 1px solid #ddd;\r\n  }\r\n  \r\n  .footer h5 {\r\n    font-size: 1.2rem;\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .footer ul {\r\n    list-style-type: none;\r\n    padding-left: 0;\r\n  }\r\n  \r\n  .footer ul li {\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .footer ul li a {\r\n    color: #343a40;\r\n    text-decoration: none;\r\n  }\r\n  \r\n  .footer ul li a:hover {\r\n    text-decoration: underline;\r\n  }\r\n  \r\n  .footer .text-center {\r\n    margin-top: 20px;\r\n    font-size: 0.9rem;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
