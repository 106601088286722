import api from '../api';
import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaUserTag } from 'react-icons/fa';
import DashboardNavbar from './DashboardNavbar';
import { AuthContext } from '../context/AuthContext';
import Swal from 'sweetalert2';

const ApplySenderName = () => {
  const { user } = useContext(AuthContext);
  const [senderName, setSenderName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [purpose, setPurpose] = useState('');
  const [file, setFile] = useState(null);

  const purposeOptions = [
    'ใช้เพื่อแจ้งข่าวสาร ประชาสัมพันธ์​',
    'ใช้เพื่อแจ้งผลการสมัคร / รหัสผ่าน / การยืนยันตัวตน (OTP)'
  ];

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // SweetAlert2 confirmation dialog
    const result = await Swal.fire({
      title: 'ยืนยันการส่งข้อมูล?',
      text: 'กรุณาตรวจสอบข้อมูลให้ครบถ้วนก่อนส่ง!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ใช่, ส่งข้อมูล',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true
    });

    if (result.isConfirmed) {
      const formData = new FormData();
      formData.append('sender_name', senderName);
      formData.append('purpose', purpose);
      if (file) {
        formData.append('file', file);
      }

      try {
        const response = await api.post('/apply-sender-name', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        Swal.fire({
          title: 'สำเร็จ!',
          text: 'ส่งใบสมัครเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ตกลง'
        });
        // Clear form fields
        setSenderName('');
        setContactEmail('');
        setPurpose('');
        setFile(null);
      } catch (error) {
        Swal.fire({
          title: 'ข้อผิดพลาด!',
          text: error.response.data.error,
          icon: 'error',
          confirmButtonText: 'ตกลง'
        });
      }
    }
  };

  return (
    <>
      <DashboardNavbar />
      <Container fluid className="p-4" style={{ backgroundColor: '#F5F5F5' }}>
        <Row className="mb-4">
          <Col>
            <h2 className="text-primary text-center"><FaUserTag /> ขอชื่อผู้ส่ง (Sender Name)</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mx-auto">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formSenderName" className="mb-3">
                <Form.Label>ชื่อผู้ส่ง</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="กรอกชื่อผู้ส่ง"
                  value={senderName}
                  onChange={(e) => setSenderName(e.target.value)}
                  required
                />
                <Form.Text className="text-muted">
                  * เป็นตัวเลข ภาษาอังกฤษ หรืออักขระพิเศษ ตั้งแต่ 4-10 ตัวอักษร อักขระพิเศษที่สามารถใช้งานได้คือ . (dot) - (Hyphen) และ _ (underscore)
                  ไม่สามารถใช้หมายเลขโทรศัพท์เป็นชื่อผู้ส่งได้ ไม่สามารถเว้นวรรคได้ กรุณากดตรวจสอบ
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formPurpose" className="mb-3">
                <Form.Label>วัตถุประสงค์ในการขอ</Form.Label>
                <Form.Control
                  as="select"
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                  required
                >
                  <option value="">เลือกวัตถุประสงค์</option>
                  {purposeOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {purpose === 'ใช้เพื่อแจ้งผลการสมัคร / รหัสผ่าน / การยืนยันตัวตน (OTP)' && (
                <Form.Group controlId="formFileUpload" className="mb-3">
                  <Form.Label>อัพโหลดภาพถ่ายบัตรประชาชน</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    required
                  />
                </Form.Group>
              )}
              <Button variant="primary" type="submit" className="w-100">
                ขออนุมัติชื่อผู้ส่ง
              </Button>
              <Form.Text className="text-muted mt-3">
                * การอนุมัติการตั้งชื่อผู้ส่ง (Sender Name) จะมี ระยะเวลา 1 - 3 วันทำการ หลังจากได้รับการอนุมัติการยืนยันตัวตนแล้ว ถ้าหากได้รับการอนุมัติแล้วจะมีเจ้าหน้าที่แจ้งผลให้ท่านทางอีเมล
              </Form.Text>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ApplySenderName;