import React from 'react';
import { Card, Button, Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FaLine } from "react-icons/fa";
import logo from '../assets/logo.png';
import './Page_Price.css';
import NavbarHome from "./NavbarHome"

const Page_Price = () => {
  const plans = [
    {
      name: 'START',
      price: '฿3,000',
      costPerMessage: '0.50 บาท/ข้อความ',
      messages: '6,000 ข้อความ',
      duration: '1 ปี',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'secondary'
    },
    {
      name: 'BASIC',
      price: '฿10,000',
      costPerMessage: '0.45 บาท/ข้อความ',
      messages: '22,222 ข้อความ',
      duration: '1 ปี',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'primary'
    },
    {
      name: 'STANDARD',
      price: '฿30,000',
      costPerMessage: '0.42 บาท/ข้อความ',
      messages: '71,429 ข้อความ',
      duration: '2 ปี',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'primary',
      recommended: true
    },
    {
      name: 'PRO',
      price: '฿50,000',
      costPerMessage: '0.38 บาท/ข้อความ',
      messages: '131,579 ข้อความ',
      duration: '3 ปี',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'primary'
    },
    {
      name: 'ADVANCE',
      price: '฿100,000',
      costPerMessage: '0.32 บาท/ข้อความ',
      messages: '312,500 ข้อความ',
      duration: '3 ปี',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'primary'
    }
  ];

  return (
    <>
    
      <NavbarHome />

      <Container className="mt-5 text-center">
        <h1>แพ็คเกจส่ง SMS ราคาถูก</h1>
        <p className="text-center text-muted">
          ต้องการซื้อ sms จำนวนมากในราคาพิเศษ <a href="#">กรุณาติดต่อเรา</a>
        </p>
        <p className="text-center text-success">
          *เครดิตที่ส่งไม่สำเร็จจะทำการคืนเครดิตภายใน 48 ชั่วโมง
        </p>
        <Row className="justify-content-center">
          {plans.map((plan, index) => (
            <Col key={index} md={2} className="mb-4-custom">
              <Card className={`text-center card-custom ${plan.recommended ? 'border-success-custom' : ''}`}>
                {plan.recommended && (
                  <div className="recommended-label">แนะนำ</div>
                )}
                <Card.Body>
                  <Card.Title className="card-title-custom">{plan.name}</Card.Title>
                  <Card.Subtitle className="mb-2 card-subtitle-custom text-muted">{plan.price}</Card.Subtitle>
                  <Card.Text className="card-text-custom">
                    <small>{plan.costPerMessage}</small><br />
                    <small>{plan.messages}</small><br />
                    <small>{plan.duration}</small><br />
                    <small>{plan.senders}</small><br />
                    <small>{plan.credit}</small>
                  </Card.Text>
                  <Button variant={plan.buttonVariant}>เลือกแพ็คเกจ</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="mt-5 gy-3" md={1} xs={1} style={{ marginBottom: '2.5rem' }}>
            <Col className="text-center font-weight-bold fs-4">หมายเหตุ</Col>
            <Col className="blue-line mx-auto mt-3"></Col>
            <Col style={{textAlign: 'left'}}>
              <p className="text-muted">
              ● ราคานี้ยังไม่รวมภาษีมูลค่าเพิ่ม 7 %
              </p>
              <p className="text-muted">
              ● ทุกแพ็คเกจสามารถเปลี่ยนชื่อผู้ส่งได้
              </p>
              <p className="text-muted">
              ● กรณีต้องการหักภาษี ณ. ที่จ่าย 3% ต้องซื้อในนามนิติบุคคลเท่านั้น
              </p>
              <p className="text-muted">
              ● ทางบริษัท ไม่สนับสนุนให้นำไปใช้ในทางที่ผิดกฏหมาย หากผู้ใดนำไปใช้ในทางที่มิชอบด้วยกฏหมายทางบริษัทฯ จะไม่รับผิดชอบและไม่คืนค่าใช้จ่ายใดๆทั้งสิ้น รวมถึงขอสงวนสิทธิ์ระงับให้บริการทุกกรณี หากเกิดคดีความทางกฏหมาย
              </p>
            </Col>
        </Row>
      </Container>

    </>
  );
};

export default Page_Price;
