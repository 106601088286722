import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import logo from '../assets/logo.png';

const Maintenance = () => {
  return (
    <Container fluid className="d-flex align-items-center justify-content-center min-vh-100 bg-light">
      <Row>
        <Col>
          <Card className="text-center shadow-lg p-4">
            <Card.Body>
              <Card.Title as="h1" className="mb-4">
                ขออภัยในความไม่สะดวก
              </Card.Title>
              <Card.Text className="mb-4">
                ขณะนี้กำลังอัพเดทปรับปรุงเว็บไซต์ให้ดีขึ้นเพื่อเพิ่มประสิทธิภาพในการใช้งาน
              </Card.Text>
              <Button href="/" variant="primary">กลับสู่หน้าแรก</Button>
            </Card.Body>
            <Card.Footer style={{border: 'none'}}>
                <img
                style={{ marginRight: '1rem' }}
                src={logo}
                width="200"
                height="45"
                alt="SIAM-SMS logo"
                />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Maintenance;