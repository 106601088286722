// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #007bff;
    border-radius: 10px;
    background-color: #f1f9ff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
  }
  
  .register-title {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .register-form .form-group {
    margin-bottom: 15px;
  }
  
  .register-form .form-label {
    color: #007bff;
  }
  
  .register-form .form-control {
    border-radius: 5px;
    border: 1px solid #007bff;
  }
  
  .register-button {
    width: 100%;
    background-color: #007bff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
  }
  
  .register-button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Register.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;IACzB,2CAA2C;EAC7C;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".register-container {\r\n    max-width: 400px;\r\n    margin: 50px auto;\r\n    padding: 20px;\r\n    border: 1px solid #007bff;\r\n    border-radius: 10px;\r\n    background-color: #f1f9ff;\r\n    box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);\r\n  }\r\n  \r\n  .register-title {\r\n    text-align: center;\r\n    margin-bottom: 20px;\r\n    color: #007bff;\r\n  }\r\n  \r\n  .register-form .form-group {\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .register-form .form-label {\r\n    color: #007bff;\r\n  }\r\n  \r\n  .register-form .form-control {\r\n    border-radius: 5px;\r\n    border: 1px solid #007bff;\r\n  }\r\n  \r\n  .register-button {\r\n    width: 100%;\r\n    background-color: #007bff;\r\n    border: none;\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n    color: #fff;\r\n    font-size: 16px;\r\n  }\r\n  \r\n  .register-button:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
