import React from 'react';
import { Container, Row, Col, Button, Navbar, Nav, Card, Image, NavDropdown } from 'react-bootstrap';
import './Home.css'; // Import custom CSS for styling
import logo from '../assets/logo.png';
import { FaLine, FaShippingFast, FaShieldAlt, FaUserTie } from "react-icons/fa";
import { IoMdPricetags } from "react-icons/io";
import NavbarHome from "./NavbarHome"

const Home = () => {
  return (
    <div>
      
      <NavbarHome />

      <Container className="home-content">
        <h1 className="title">บริการส่ง SMS ครบวงจร ใช้งานง่าย คุณภาพสูง ราคาต่ำสุด 0.17 บาท/ข้อความ</h1>
        <Image style={{borderRadius: '20px'}} src="./images/background_home_top_1.jpg" fluid/>
        {/* <Row className="align-items-center">
          <Col md={6} className="text-center text-md-start">
            <h1 className="title">บริการส่ง SMS ครบวงจร</h1>
            <h2 className="subtitle">ใช้งานง่าย คุณภาพสูง</h2>
            <h3 className="price">ราคาต่ำสุด 0.17 บาท/ข้อความ</h3>
            <p>คิดเงินเฉพาะที่ส่งถึง</p>
            <Button variant="primary" className="me-2">สั่งซื้อ</Button>
            <Button variant="outline-primary">ทดลองส่งฟรี</Button>
          </Col>
        </Row> */}
      </Container>

      <section className="features">
        <Container>
          <Row className="gy-3" md={1} xs={1} style={{ marginBottom: '2.5rem' }}>
            <Col className="text-center font-weight-bold fs-4 text-moblie">เพิ่มประสิทธิภาพให้ธุรกิจของคุณด้วย SMS Marketing</Col>
            <Col className="blue-line mx-auto mt-3"></Col>
          </Row>
          <Row className="gy-3">
            <Col xs={6} sm={6} md={3}>
              <Card className="h-100 card-hover">
                <Card.Header className="text-center">
                  <IoMdPricetags size={50} className="card-header-icon" />
                </Card.Header>
                <Card.Body>
                  <Card.Title className="text-center card-title">ราคาถูก</Card.Title>
                  <Card.Text className="card-text">
                    เพื่อประหยัดเงินในการทำการตลาดเราให้เรทที่ดีที่สุด
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Card className="h-100 card-hover">
                <Card.Header className="text-center">
                  <FaShippingFast size={50} className="card-header-icon" />
                </Card.Header>
                <Card.Body>
                  <Card.Title className="text-center card-title">ส่งไว</Card.Title>
                  <Card.Text className="card-text">
                    ระบบอัตโนมัติของเรา ส่งด้วย API ความเร็วสูง
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Card className="h-100 card-hover">
                <Card.Header className="text-center">
                  <FaShieldAlt size={50} className="card-header-icon" />
                </Card.Header>
                <Card.Body>
                  <Card.Title className="text-center card-title">ความปลอดภัย</Card.Title>
                  <Card.Text className="card-text">
                    ระบบของเรามีการป้องกันความปลอดภัยสูงสุด
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Card className="h-100 card-hover">
                <Card.Header className="text-center">
                  <FaUserTie size={50} className="card-header-icon" />
                </Card.Header>
                <Card.Body>
                  <Card.Title className="text-center card-title">บริการลูกค้า</Card.Title>
                  <Card.Text className="card-text">
                    มีทีมงานพร้อมช่วยเหลือคุณตลอด 24 ชั่วโมง
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="features">
        <Container>
          <Row className="gy-3" md={1} xs={1}>
            <Col className="text-center font-weight-bold fs-4 text-moblie">ราคาต่ำสุด 0.24 บาท/ข้อความ คิดเงินเฉพาะที่ส่งถึง</Col>
            <Col className="blue-line mx-auto mt-3"></Col>
          </Row>
          <Image style={{borderRadius: '20px', marginTop: '3rem'}} src="./images/background_home_top_2.jpg" fluid/>
          {/* <Container className="home-content2">
            <Row className="align-items-center">
              <Col md={6}  className="ms-auto text-md-end">
                <h1 className="title">ฟีเจอร์ที่โดดเด่นไม่เหมือนใคร</h1>
                <h3 className="price">● ขอชื่อผู้ส่ง ไม่ยุ่งยาก</h3>
                <h3 className="price">● ระบบรายงานผลแบบ Realtime</h3>
                <h3 className="price">● มีทีมซัพพอร์ตคอยให้บริการตลอด</h3>
              </Col>
            </Row>
          </Container>
          <Container className="home-content2-mobile">
            <Row className="align-items-center">
              <Col xs={12}>
                  <Image style={{borderRadius: '1rem'}} src="./images/background_home_2.png" fluid/>
              </Col>
              <Col md={6} style={{marginTop: '1rem'}} className="text-center font-weight-bold fs-4 text-moblie">ฟีเจอร์ที่โดดเด่นไม่เหมือนใคร</Col>
              <Col md={6} className="blue-line mx-auto mt-3"></Col>
              <Col md={6} style={{marginTop: '1rem', paddingTop: '1rem'}} className="ms-auto text-md-end">
                <h3 className="price">● ขอชื่อผู้ส่ง ไม่ยุ่งยาก</h3>
                <h3 className="price">● ระบบรายงานผลแบบ Realtime</h3>
                <h3 className="price">● มีทีมซัพพอร์ตคอยให้บริการตลอด</h3>
              </Col>
            </Row>
          </Container> */}
          <Container className="additional-content">
            <Row className="gy-3" style={{ marginTop: '2rem' }}>
              <Col md={6} className="text-center text-md-start">
                <h3 className="additional-content-title">การจัดการรายชื่อผู้ติดต่อ</h3>
                <p>เรามีระบบการจัดการรายชื่อผู้ติดต่อที่สะดวกและง่ายดาย คุณสามารถนำเข้ารายชื่อจากไฟล์ต่างๆ หรือเชื่อมต่อกับฐานข้อมูลภายนอกได้อย่างราบรื่น</p>
              </Col>
              <Col md={6} className="text-center text-md-end">
                <h3 className="additional-content-title">การแบ่งกลุ่มลูกค้า</h3>
                <p>ระบบของเราช่วยให้คุณแบ่งกลุ่มลูกค้าได้ตามความต้องการ ทำให้การส่งข้อความเป็นไปอย่างมีประสิทธิภาพและตรงกลุ่มเป้าหมาย</p>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>

      <section className="sms-service-section" style={{ marginTop: '2rem' }}>
        <Container>
          <Row className="gy-3" md={1} xs={1} style={{ marginBottom: '2rem' }}>
            {/* <Col className="text-center font-weight-bold fs-4 text-moblie">บริการ SMS ที่รวดเร็วและคุ้มค่า เราคือตัวเลือกที่ดีที่สุด</Col> */}
            <Col className="blue-line mx-auto mt-3"></Col>
          </Row>
          <Image style={{borderRadius: '20px'}} src="./images/background_home_top_3.jpg" fluid/>
          {/* <Row>
            <Col md={6}>
              <Image src="./images/cheap_sms.png" fluid alt="ส่ง SMS ราคาถูก" className="rounded" />
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center">
              <h3 className="sms-section-title">ส่ง SMS ราคาถูก</h3>
              <p>เราให้บริการส่ง SMS ในราคาที่คุ้มค่าที่สุด พร้อมประสิทธิภาพที่เหนือกว่าใคร คิดเงินเฉพาะข้อความที่ส่งถึงผู้รับเท่านั้น คุณสามารถเลือกแพ็กเกจที่เหมาะสมกับความต้องการของคุณได้เลย</p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <Image src="./images/fast_sms.png" fluid alt="ส่ง SMS รวดเร็ว" className="rounded" />
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center">
              <h3 className="sms-section-title">ส่ง SMS รวดเร็ว</h3>
              <p>บริการของเรามาพร้อมกับระบบส่ง SMS ที่รวดเร็ว ด้วยการใช้เทคโนโลยีทันสมัย คุณสามารถส่งข้อความถึงลูกค้าของคุณในทันที ไม่พลาดทุกโอกาสในการสื่อสารกับลูกค้า</p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <Image src="./images/easy_use.png" fluid alt="ระบบใช้งานง่าย" className="rounded" />
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center">
              <h3 className="sms-section-title">ระบบใช้งานง่าย</h3>
              <p>ระบบ SMS ที่ออกแบบมาอย่างดีจะมีอินเตอร์เฟซที่เรียบง่ายและใช้งานสะดวก ไม่จำเป็นต้องมีประสบการณ์หรือความรู้ลึกซึ้งเกี่ยวกับเทคโนโลยี ผู้ใช้สามารถเริ่มต้นส่งข้อความได้ภายในไม่กี่คลิก</p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <Image src="./images/subport.png" fluid alt="ทีมงานดูแล" className="rounded" />
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center">
              <h3 className="sms-section-title">ทีมงานดูแล 24 ชม.</h3>
              <p>ทีมงานดูแล 24 ชม. มีความพร้อมในการให้บริการไม่ว่าจะเป็นเวลาใดก็ตาม ทำให้ลูกค้าสามารถติดต่อขอความช่วยเหลือได้ทุกเมื่อที่ต้องการ ไม่ว่าจะเป็นการแก้ไขปัญหาเทคนิค การช่วยเหลือในการตั้งค่าระบบ</p>
            </Col>
          </Row> */}
        </Container>
      </section>

      <section className="standard-section" style={{ marginBottom: '5rem', marginTop: '3rem' }}>
        <Container>
          <Row className="gy-3" md={1} xs={1} style={{ marginBottom: '1rem', marginTop: '2rem' }}>
            {/* <Col className="text-center font-weight-bold fs-4">มาตรฐานบริการ SMS ได้รับการรับรองจาก กสทช.</Col> */}
            <Col className="blue-line mx-auto mt-3"></Col>
            <Col className="d-md-flex align-items-center justify-content-center">
              <Image style={{borderRadius: '20px', width: '100%'}} src="./images/background_home_bt_1.jpg" fluid/>
            </Col>
          </Row>
        </Container>
      </section>

    </div>
  );
};

export default Home;