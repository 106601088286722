import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContext';
import DashboardNavbar from './DashboardNavbar';
import avatar_logo from '../assets/user_avatar.png';
import Swal from 'sweetalert2';
import api from '../api';

const UserProfile = () => {
  const { user } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await api.get(`/user-info/${user.id}`);
        setUserInfo(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    if (user && user.id) {
      fetchUserInfo();
    }
  }, [user]);

  return (
    <>
      <DashboardNavbar />
      <Container fluid className="p-4" style={{ backgroundColor: '#F9FAFB' }}>
        <Row className="mb-4">
          <Col>
            <h2 className="text-primary text-center">ข้อมูลส่วนตัว</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mx-auto">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <div className="text-center mb-4">
                  <Image
                    src={avatar_logo}
                    roundedCircle
                    width={150}
                    height={150}
                    alt="User Avatar"
                    className="mb-3"
                  />
                  <h4>{userInfo.first_name} {userInfo.last_name}</h4>
                </div>
                <Card.Text>
                  <strong>อีเมล์:</strong> {userInfo.email}
                </Card.Text>
                <Card.Text>
                  <strong>เบอร์โทรศัพท์:</strong> {userInfo.contact_phonenumber}
                </Card.Text>
                <Card.Text>
                  <strong>เครดิต:</strong> {userInfo.credits}
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="primary" onClick={() => Swal.fire('ยังไม่ได้เพิ่มการแก้ไขโปรไฟล์')}>
                    แก้ไขโปรไฟล์
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProfile;