import React, { useState, useContext } from 'react';
import { Form, Button, Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Swal from 'sweetalert2';
import logo from '../assets/logo.png';
import { FaLine } from "react-icons/fa";
import './Register.css';
import NavbarHome from "./NavbarHome"

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { register, checkEmailExists } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      Swal.fire('Error', 'รหัสผ่านไม่ตรงกัน', 'error');
      return;
    }

    const emailExists = await checkEmailExists(email);
    if (emailExists) {
      Swal.fire('Error', 'อีเมลนี้มีผู้ใช้แล้ว', 'error');
      return;
    }

    try {
      await register(email, password, firstName, lastName, phoneNumber);
      Swal.fire({
        title: 'สมัครสมาชิกสำเร็จ',
        text: 'ระบบกำลังพาท่านไปยังหน้าเข้าสู่ระบบ..',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
      });
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      Swal.fire('Error', 'Registration failed', 'error');
    }
  };

  return (
    <>
    
      <NavbarHome />

      <Container className="register-container">
        <h2 className="register-title">สมัครสมาชิก</h2>
        <Form onSubmit={handleSubmit} className="register-form">
          <Form.Group controlId="formFirstName">
            <Form.Label>ชื่อ</Form.Label>
            <Form.Control
              type="text"
              placeholder="กรอกชื่อ"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formLastName">
            <Form.Label>นามสกุล</Form.Label>
            <Form.Control
              type="text"
              placeholder="กรอกนามสกุล"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formPhoneNumber">
            <Form.Label>เบอร์โทรศัพท์</Form.Label>
            <Form.Control
              type="text"
              placeholder="กรอกเบอร์โทรศัพท์"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>อีเมล</Form.Label>
            <Form.Control
              type="email"
              placeholder="กรอกอีเมล"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>รหัสผ่าน</Form.Label>
            <Form.Control
              type="password"
              placeholder="กรอกรหัสผ่าน"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formConfirmPassword">
            <Form.Label>ยืนยันรหัสผ่าน</Form.Label>
            <Form.Control
              type="password"
              placeholder="กรอกยืนยันรหัสผ่าน"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>

          <Button style={{marginTop: '1rem'}} variant="primary" type="submit" className="register-button">
            สมัครสมาชิก
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default Register;