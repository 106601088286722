import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, ProgressBar } from 'react-bootstrap';
import DashboardNavbar from './DashboardNavbar';
import { MdDashboardCustomize } from "react-icons/md";
import { BiMessageCheck, BiMessageError, BiMessageDots, BiMessageDetail, BiSolidMessageAlt } from "react-icons/bi";
import api from '../api';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('th');

const Dashboard = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [totals, setTotals] = useState({
    totalMessagesSent: 0,
    totalMessagesDelivered: 0,
    totalMessagesFailed: 0,
    deliveryRate: 0
  });

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await api.get('/campaigns');

        // Processing campaigns data to calculate messages_sent and delivery_rate
        const processedCampaigns = response.data.map(campaign => {
          const messagesSent = campaign.phone_numbers.length;
          const deliveryRate = (campaign.messages_delivered / messagesSent) * 100;
          return {
            ...campaign,
            messages_sent: messagesSent,
            messages_processing: (campaign.phone_numbers.length - campaign.messages_delivered - campaign.messages_failed),
            delivery_rate: deliveryRate.toFixed(2) // Formatting to 2 decimal places
          };
        });

        // Sort campaigns from newest to oldest
        const sortedCampaigns = processedCampaigns.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setCampaigns(sortedCampaigns);

        // Calculating totals
        const totalMessagesSent = sortedCampaigns.reduce((acc, campaign) => acc + campaign.messages_sent, 0);
        const totalMessagesProcess = sortedCampaigns.reduce((acc, campaign) => acc + campaign.messages_processing, 0);
        const totalMessagesDelivered = sortedCampaigns.reduce((acc, campaign) => acc + campaign.messages_delivered, 0);
        const totalMessagesFailed = sortedCampaigns.reduce((acc, campaign) => acc + campaign.messages_failed, 0)+totalMessagesProcess;
        const deliveryRate = ((totalMessagesDelivered / totalMessagesSent) * 100).toFixed(2);

        setTotals({
          totalMessagesSent,
          totalMessagesDelivered,
          totalMessagesFailed,
          deliveryRate
        });
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    fetchCampaigns();
  }, []);

  return (
    <>
      <DashboardNavbar />
      <Container fluid className="p-4" style={{ backgroundColor: '#F5F5F5' }}>
        <Row className="mb-4">
          <Col>
            <h2 className="text-primary text-center"><MdDashboardCustomize /> แดชบอร์ดบริการส่งข้อความ</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={3}>
            <Card className="mb-4 text-center">
              <Card.Body>
                <Card.Title>ข้อความที่ส่งทั้งหมด</Card.Title>
                <Card.Text>{totals.totalMessagesSent.toLocaleString()}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card className="mb-4 text-center">
              <Card.Body>
                <Card.Title><BiMessageCheck style={{color: 'green'}} size={25} className="stat-icon"/></Card.Title>
                <Card.Text style={{fontSize: '1rem', fontWeight: '600'}}>ข้อความที่สำเร็จ</Card.Text>
                <Card.Footer style={{margin: '1rem', border: 'none', borderRadius: '1rem'}}>{totals.totalMessagesDelivered.toLocaleString()}</Card.Footer>
                <ProgressBar variant="success" now={totals.deliveryRate} label={`${totals.deliveryRate}%`} />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card className="mb-4 text-center">
              <Card.Body>
                <Card.Title><BiMessageError style={{color: 'red'}} size={25} className="stat-icon"/></Card.Title>
                <Card.Text style={{fontSize: '1rem', fontWeight: '600'}}>ข้อความที่ล้มเหลว</Card.Text>
                <Card.Footer style={{margin: '1rem', border: 'none', borderRadius: '1rem'}}>{totals.totalMessagesFailed.toLocaleString()}</Card.Footer>
                <ProgressBar variant="danger" now={100 - totals.deliveryRate} label={`${(100 - totals.deliveryRate).toFixed(2)}%`} />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card className="mb-4 text-center">
              <Card.Body>
                <Card.Title>อัตราการจัดส่ง</Card.Title>
                <Card.Text>{totals.deliveryRate}%</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>แคมเปญทั้งหมด</Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>ชื่อแคมเปญ</th>
                        <th>ข้อความ</th>
                        <th>จำนวนข้อความที่ส่ง</th>
                        <th>สำเร็จ</th>
                        <th>ล้มเหลว</th>
                        <th>รอผลลัพธ์</th>
                        <th>วันที่</th>
                      </tr>
                    </thead>
                    <tbody>
                      {campaigns.map(campaign => (
                        <tr key={campaign.id}>
                          <td>{campaign.id}</td>
                          <td>{campaign.campaign_name}</td>
                          <td>{campaign.message}</td>
                          <td>{campaign.messages_sent}</td>
                          <td>{campaign.messages_delivered}</td>
                          <td>{campaign.messages_failed}</td>
                          <td>{campaign.messages_processing}</td>
                          <td>{dayjs(campaign.created_at).format('LLL')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
