// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Spinner CSS */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0; /* Light grey background for contrast */
  }
  
  .spinner {
    border: 8px solid #f3f3f3; /* Light grey border */
    border-top: 8px solid #3498db; /* Blue color for the spinning part */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 18px;
    color: #555; /* Soft grey color for the text */
    font-family: 'Arial', sans-serif;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Spinner.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB,EAAE,uCAAuC;EACpE;;EAEA;IACE,yBAAyB,EAAE,sBAAsB;IACjD,6BAA6B,EAAE,qCAAqC;IACpE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW,EAAE,iCAAiC;IAC9C,gCAAgC;EAClC","sourcesContent":["/* Spinner CSS */\r\n.spinner-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    background-color: #f0f0f0; /* Light grey background for contrast */\r\n  }\r\n  \r\n  .spinner {\r\n    border: 8px solid #f3f3f3; /* Light grey border */\r\n    border-top: 8px solid #3498db; /* Blue color for the spinning part */\r\n    border-radius: 50%;\r\n    width: 60px;\r\n    height: 60px;\r\n    animation: spin 1s linear infinite;\r\n  }\r\n  \r\n  @keyframes spin {\r\n    0% { transform: rotate(0deg); }\r\n    100% { transform: rotate(360deg); }\r\n  }\r\n  \r\n  .loading-text {\r\n    margin-top: 20px;\r\n    font-size: 18px;\r\n    color: #555; /* Soft grey color for the text */\r\n    font-family: 'Arial', sans-serif;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
