import React from 'react';
import { Container, Row, Col, Button, Navbar, Nav, Card, Image, NavDropdown } from 'react-bootstrap';
import './Home.css'; // Import custom CSS for styling
import logo from '../assets/logo.png';
import { FaLine, FaShippingFast, FaShieldAlt, FaUserTie } from "react-icons/fa";
import { IoMdPricetags } from "react-icons/io";
import NavbarHome from "./NavbarHome"

const Page_Otp = () => {
  return (
    <div>

      <NavbarHome />

      <Container className="home-content" style={{marginTop: '3rem'}}>
        <Image style={{borderRadius: '20px', marginBottom: '1rem'}} src="./images/background_home_top_4.jpg" fluid/>
        <Row className="align-items-center">
          <Col md={6} className="text-center text-md-start" style={{marginBottom: '1rem'}}>
            <h2 className="subtitle">SMS OTP: การยืนยันตัวตนด้วยความปลอดภัยสูง</h2>
            <p>SMS OTP (One-Time Password) คือรหัสผ่านแบบใช้ครั้งเดียวที่ถูกส่งผ่านข้อความ SMS ไปยังโทรศัพท์มือถือของผู้ใช้ โดยรหัสนี้ถูกใช้ในการยืนยันตัวตนเมื่อเข้าสู่ระบบหรือทำธุรกรรมต่าง ๆ ทางออนไลน์ ซึ่ง OTP นั้นจะมีความปลอดภัยสูง เนื่องจากเป็นรหัสที่เปลี่ยนแปลงทุกครั้ง และสามารถใช้ได้เพียงครั้งเดียวในระยะเวลาที่จำกัด</p>
          </Col>
          <Col md={6} className="text-center text-md-start" style={{marginBottom: '1rem'}}>
            <h2 className="subtitle">ความสำคัญของ SMS OTP</h2>
            <p>การรักษาความปลอดภัยของข้อมูลส่วนตัวและการทำธุรกรรมออนไลน์เป็นสิ่งสำคัญมากขึ้นเรื่อย ๆ ในปัจจุบัน เพื่อป้องกันการโจรกรรมข้อมูลหรือการแฮกเกอร์ SMS OTP ได้กลายเป็นหนึ่งในเครื่องมือที่ช่วยเพิ่มความปลอดภัยของการยืนยันตัวตนได้อย่างดีเยี่ยม เนื่องจาก OTP เป็นรหัสที่ถูกสร้างขึ้นใหม่ทุกครั้งที่มีการร้องขอ และถูกส่งตรงไปยังผู้ใช้ผ่านช่องทางที่เฉพาะเจาะจงอย่างหมายเลขโทรศัพท์มือถือของผู้ใช้เอง</p>
          </Col>
          <Col md={6} className="text-center text-md-start" style={{marginBottom: '1rem'}}>
            <h2 className="subtitle">ข้อดีของ SMS OTP</h2>
            <p>ความปลอดภัยสูง: เนื่องจากรหัส OTP มีอายุการใช้งานที่สั้นและถูกสร้างขึ้นใหม่ทุกครั้ง ทำให้ผู้ไม่หวังดีไม่สามารถคาดเดาหรือขโมยรหัสได้</p>
            <p>สะดวกสบาย: ผู้ใช้ไม่จำเป็นต้องจดจำรหัสผ่านที่ซับซ้อนหลายชุด เพียงแค่มีโทรศัพท์มือถือที่ลงทะเบียนไว้</p>
            <p>การเข้าถึงที่ง่ายดาย: ไม่จำเป็นต้องติดตั้งแอปพลิเคชันเสริม รหัส OTP ถูกส่งผ่านข้อความ SMS ที่ทุกคนสามารถเข้าถึงได้</p>
          </Col>
          <Col md={6} className="text-center text-md-start" style={{marginBottom: '1rem'}}>
          <h2 className="subtitle">ข้อจำกัดของ SMS OTP</h2>
            <p>การพึ่งพาเครือข่ายมือถือ: หากไม่มีสัญญาณโทรศัพท์มือถือหรือเครือข่ายมีปัญหา การรับ OTP อาจเกิดความล่าช้า</p>
            <p>ค่าใช้จ่าย: การส่งข้อความ SMS OTP มีค่าใช้จ่าย ซึ่งหากใช้บ่อยครั้งหรือส่งไปยังหลายผู้ใช้อาจเป็นภาระต้นทุนที่สูง</p>
            <p>ช่องโหว่ด้าน SIM Swap: แม้จะปลอดภัย แต่ก็ยังมีความเสี่ยงจากการโจมตีที่เรียกว่า SIM Swap ซึ่งเป็นการสวมซิมการ์ดของเหยื่อ</p>
          </Col>
          <Image style={{borderRadius: '20px', marginBottom: '1rem'}} src="./images/background_home_bt_2.jpg" fluid/>
          <Col md={12} className="text-center text-md-start" style={{marginBottom: '1rem'}}>
            <h2 className="subtitle">การทำงานของ SMS OTP</h2>
            <p className="text-md-start">1. ร้องขอ OTP: เมื่อผู้ใช้ทำการล็อกอินหรือทำธุรกรรมใด ๆ ที่ต้องการการยืนยันตัวตน ระบบจะส่ง OTP ผ่าน SMS ไปยังเบอร์โทรศัพท์ที่ได้ลงทะเบียนไว้</p>
            <p className="text-md-start">2. กรอกรหัส OTP: ผู้ใช้จะต้องกรอกรหัสที่ได้รับผ่าน SMS ภายในเวลาที่กำหนด (เช่น 5-10 นาที) หากหมดเวลา รหัสจะไม่สามารถใช้งานได้</p>
            <p className="text-md-start">3. ตรวจสอบ OTP: ระบบจะทำการตรวจสอบว่ารหัสที่กรอกถูกต้องหรือไม่ หากถูกต้อง ผู้ใช้จะได้รับอนุญาตให้เข้าถึงระบบหรือตกลงทำธุรกรรม</p>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default Page_Otp;