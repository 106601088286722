// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-muted {
  color: #6c757d !important;
}

.text-success {
  color: #4285EC !important;
}

.card-custom {
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card-custom:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.card-title-custom {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-subtitle-custom {
  font-size: 1.25rem;
}

.card-text-custom {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.border-success-custom {
  border: 2px solid #4285EC !important;
}

.mb-4-custom {
  margin-bottom: 1.5rem !important;
}

.recommended-label {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff4c4c;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Page_Price.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,uCAAuC;EACvC,2CAA2C;AAC7C;;AAEA;EACE,sBAAsB;EACtB,uCAAuC;AACzC;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".text-muted {\r\n  color: #6c757d !important;\r\n}\r\n\r\n.text-success {\r\n  color: #4285EC !important;\r\n}\r\n\r\n.card-custom {\r\n  border-radius: 15px;\r\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\r\n  transition: transform 0.3s, box-shadow 0.3s;\r\n}\r\n\r\n.card-custom:hover {\r\n  transform: scale(1.05);\r\n  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.card-title-custom {\r\n  font-size: 1.5rem;\r\n  font-weight: bold;\r\n}\r\n\r\n.card-subtitle-custom {\r\n  font-size: 1.25rem;\r\n}\r\n\r\n.card-text-custom {\r\n  margin-top: 1rem;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.border-success-custom {\r\n  border: 2px solid #4285EC !important;\r\n}\r\n\r\n.mb-4-custom {\r\n  margin-bottom: 1.5rem !important;\r\n}\r\n\r\n.recommended-label {\r\n  position: absolute;\r\n  top: -10px;\r\n  right: -10px;\r\n  background-color: #ff4c4c;\r\n  color: white;\r\n  padding: 5px 10px;\r\n  border-radius: 5px;\r\n  font-size: 0.8rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
