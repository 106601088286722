import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { BiMailSend } from "react-icons/bi";
import DashboardNavbar from './DashboardNavbar';
import { AuthContext } from '../context/AuthContext';
import api from '../api';
import Swal from 'sweetalert2';

const SendSMS = () => {
  const [phoneNumbersInput, setPhoneNumbersInput] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [message, setMessage] = useState('');
  const [senderName, setSenderName] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [senderOptions, setSenderOptions] = useState([]);
  const { user } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSenderNames = async () => {
      try {
        const response = await api.get(`/sender-names/${user.email}`);
        setSenderOptions(response.data);
      } catch (error) {
        console.error('Error fetching sender names:', error);
      }
    };

    if (user && user.email) {
      fetchSenderNames();
    }
  }, [user]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await api.get(`/user-info/${user.id}`);
        setUserInfo(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    if (user && user.id) {
      fetchUserInfo();
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const numbersToSend = phoneNumbers.length ? phoneNumbers : phoneNumbersInput.split('\n').map(num => num.trim()).filter(num => num !== '');

    if (numbersToSend.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณากรอกเบอร์โทรผู้รับหรืออัพโหลดไฟล์เบอร์ผู้รับ',
        showConfirmButton: true,
      });
      return;
    }

    if (numbersToSend.length > 1000) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'เบอร์ผู้รับสามารถใส่ได้สูงสุด 1000 เบอร์',
        showConfirmButton: true,
      });
      return;
    }

    if (userInfo.credits < numbersToSend.length) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'เครดิตไม่เพียงพอ',
        showConfirmButton: true,
      });
      return;
    }

    setError(null);
    
    const result = await Swal.fire({
      title: 'ยืนยันการส่งข้อมูล?',
      text: `คุณกำลังจะส่งข้อความไปยังผู้รับจำนวน ${numbersToSend.length} เบอร์`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ใช่, ส่งข้อมูล',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true
    });

    if (result.isConfirmed) {
      try {
        const response = await api.post('/send-sms', {
          campaign_name: campaignName,
          sender_name: senderName,
          message,
          phone_numbers: numbersToSend
        });

        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'เพิ่มแคมเปญเข้าคิวส่งแล้ว!',
            text: `ชื่อแคมเปญ : ${campaignName}`,
            showConfirmButton: false,
            timer: 3000
          });

          setPhoneNumbersInput('');
          setPhoneNumbers([]);
          setMessage('');
          setSenderName('');
          setCampaignName('');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.error,
            showConfirmButton: true,
          });
        }
      } catch (error) {
        if (error.response.data.error === 'Insufficient credits') {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'เครดิตไม่เพียงพอ',
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: error.message,
            showConfirmButton: true,
          });
        }
        
        console.error('Error sending SMS:', error);
      }
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const numbers = text.split('\n').map(num => num.trim()).filter(num => num !== '');
        setPhoneNumbers(numbers);
        setPhoneNumbersInput(''); // Clear the input text area
      };
      reader.readAsText(file);
    }
  };

  return (
    <>
      <DashboardNavbar />
      <Container fluid className="p-4" style={{ backgroundColor: '#F5F5F5' }}>
        <Row className="mb-4">
          <Col>
            <h2 className="text-primary text-center"><BiMailSend /> ส่งข้อความ</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mx-auto">
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formSenderName" className="mb-3">
                <Form.Label>ชื่อผู้ส่ง</Form.Label>
                <Form.Control
                  as="select"
                  value={senderName}
                  onChange={(e) => setSenderName(e.target.value)}
                  required
                >
                  <option value="">เลือกชื่อผู้ส่ง</option>
                  {senderOptions.map((sender, index) => (
                    <option key={index} value={sender}>
                      {sender}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formCampaignName" className="mb-3">
                <Form.Label>ชื่อแคมเปญ</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="กรอกชื่อแคมเปญ"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhoneNumbers" className="mb-3">
                <Form.Label>เบอร์โทรผู้รับ (สูงสุด 1000 เบอร์)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={10}
                  placeholder="กรอกเบอร์โทรผู้รับ (หนึ่งเบอร์ต่อบรรทัด)"
                  value={phoneNumbersInput}
                  onChange={(e) => setPhoneNumbersInput(e.target.value)}
                  disabled={phoneNumbers.length > 0}
                />
              </Form.Group>
              <Form.Group controlId="formFileUpload" className="mb-3">
                <Form.Label>อัพโหลดไฟล์เบอร์ผู้รับ (TXT file)</Form.Label>
                <Form.Control
                  type="file"
                  accept=".txt"
                  onChange={handleFileUpload}
                />
              </Form.Group>
              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Label>ข้อความ</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="กรอกข้อความที่ต้องการส่ง"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                ส่งข้อความ
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SendSMS;
